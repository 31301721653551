import React from 'react';

const SalesforceDevelopment = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6601 33.448H5.49859C5.32498 33.448 5.15853 33.3787 5.03577 33.2553C4.91301 33.1319 4.84399 32.9646 4.84399 32.7901V11.1056C4.84399 10.0168 5.27465 8.9724 6.04068 8.2025C6.80671 7.4326 7.84551 7.00008 8.92884 7.00008H39.4852C40.0238 6.99661 40.5579 7.10019 41.0565 7.30494C41.5551 7.5097 42.0086 7.81162 42.3907 8.19318C42.7727 8.57474 43.0761 9.02846 43.2831 9.52827C43.49 10.0281 43.5963 10.5642 43.5963 11.1056V16.5529C43.5898 16.7253 43.5189 16.889 43.3975 17.011C43.2761 17.133 43.1132 17.2043 42.9417 17.2108C42.7681 17.2108 42.6016 17.1414 42.4789 17.018C42.3561 16.8947 42.2871 16.7274 42.2871 16.5529V11.1056C42.2802 10.3633 41.982 9.65392 41.4573 9.13153C40.9327 8.60914 40.2238 8.31586 39.4852 8.3159H8.92884C8.19273 8.3159 7.48683 8.61 6.96632 9.13314C6.44582 9.65627 6.15319 10.3657 6.15319 11.1056V32.1585H25.6601C25.8337 32.1585 26.0001 32.2279 26.1229 32.3513C26.2456 32.4747 26.3147 32.642 26.3147 32.8165C26.3147 32.9909 26.2456 33.1582 26.1229 33.2816C26.0001 33.405 25.8337 33.4744 25.6601 33.4744V33.448Z"
      fill="#475467"
    />
    <path
      d="M25.6724 30.7811H8.14051C7.96701 30.7811 7.80066 30.7114 7.67798 30.5873C7.5553 30.4633 7.48633 30.2951 7.48633 30.1196V10.3529C7.48633 10.1775 7.5553 10.0093 7.67798 9.88522C7.80066 9.76115 7.96701 9.69141 8.14051 9.69141H40.3C40.4735 9.69141 40.6399 9.76115 40.7626 9.88522C40.8853 10.0093 40.9542 10.1775 40.9542 10.3529V16.2008C40.9542 16.3763 40.8853 16.5445 40.7626 16.6686C40.6399 16.7926 40.4735 16.8624 40.3 16.8624C40.1265 16.8624 39.9602 16.7926 39.8375 16.6686C39.7148 16.5445 39.6459 16.3763 39.6459 16.2008V10.9085H8.79469V29.4316H25.6724C25.8459 29.4316 26.0122 29.5013 26.1349 29.6254C26.2576 29.7495 26.3266 29.9177 26.3266 30.0931C26.3301 30.1822 26.3163 30.2711 26.285 30.3544C26.2537 30.4378 26.2056 30.514 26.1445 30.5783C26.0834 30.6426 26.0103 30.6936 25.9292 30.7285C25.8481 30.7634 25.7605 30.7812 25.6724 30.7811Z"
      fill="#475467"
    />
    <path
      d="M25.3202 37.0648H3.88329C2.85337 37.0648 1.86533 36.6199 1.13706 35.828C0.40879 35.0361 0 33.9621 0 32.8422C0 32.653 0.0691605 32.4716 0.192179 32.3379C0.315197 32.2041 0.481996 32.1289 0.65597 32.1289H25.766C25.94 32.1289 26.1068 32.2041 26.2298 32.3379C26.3529 32.4716 26.422 32.653 26.422 32.8422C26.422 33.0314 26.3529 33.2127 26.2298 33.3465C26.1068 33.4803 25.94 33.5555 25.766 33.5555H1.41636C1.56801 34.1442 1.88964 34.6642 2.33369 35.0392C2.77774 35.4142 3.32121 35.6242 3.88329 35.6382H25.3202C25.4942 35.6382 25.661 35.7134 25.784 35.8472C25.907 35.981 25.9762 36.1623 25.9762 36.3515C25.9762 36.5407 25.907 36.7221 25.784 36.8558C25.661 36.9896 25.4942 37.0648 25.3202 37.0648Z"
      fill="#475467"
    />
    <path
      d="M37.4954 41.978H37.3291C25.1884 37.9397 28.0155 21.935 28.0571 21.7649C28.0686 21.6836 28.0993 21.6063 28.1464 21.5397C28.1934 21.4731 28.2555 21.4192 28.3273 21.3824L37.2667 16.919C37.339 16.8874 37.4167 16.8711 37.4954 16.8711C37.574 16.8711 37.6517 16.8874 37.724 16.919L44.8131 20.4684C44.8745 20.4991 44.9295 20.5419 44.9748 20.5943C45.02 20.6467 45.0546 20.7077 45.0768 20.7738C45.099 20.8399 45.1082 20.9099 45.1039 20.9797C45.0997 21.0495 45.0822 21.1177 45.0522 21.1805C45.0221 21.2433 44.9803 21.2994 44.9291 21.3457C44.8778 21.3919 44.8182 21.4274 44.7535 21.4501C44.6888 21.4728 44.6205 21.4823 44.5522 21.478C44.484 21.4736 44.4173 21.4556 44.3558 21.4249L37.4954 18.0028L29.0343 22.2537C28.7433 24.2517 27.2048 37.3658 37.4954 40.9366C43.7321 38.8111 45.6031 33.0936 46.0812 28.7152C46.0758 28.6379 46.0869 28.5602 46.114 28.4878C46.141 28.4154 46.1834 28.3499 46.2378 28.2959C46.2922 28.242 46.3575 28.2008 46.4291 28.1754C46.5008 28.15 46.577 28.1408 46.6525 28.1487C46.7279 28.1566 46.8005 28.1813 46.8656 28.2209C46.9308 28.2606 46.9867 28.3145 47.0293 28.3786C47.072 28.4427 47.1004 28.5155 47.1126 28.592C47.1247 28.6685 47.1204 28.7468 47.0999 28.8215C46.6009 33.5612 44.5635 39.7038 37.6408 41.9993L37.4954 41.978Z"
      fill="#475467"
    />
    <path
      d="M36.8969 33.4502C36.8256 33.4493 36.7551 33.4338 36.6899 33.4044C36.6247 33.3751 36.5661 33.3327 36.5176 33.2798L32.3039 29.0154C32.2033 28.9136 32.1467 28.7756 32.1467 28.6316C32.1467 28.4877 32.2033 28.3495 32.3039 28.2477C32.4044 28.1459 32.541 28.0888 32.6832 28.0888C32.8255 28.0888 32.9617 28.1459 33.0623 28.2477L36.981 32.2137L47.0519 22.0218C47.0972 21.9607 47.1549 21.9101 47.2211 21.8736C47.2874 21.837 47.3606 21.8154 47.4359 21.8099C47.5112 21.8045 47.5869 21.8155 47.6576 21.8422C47.7283 21.8689 47.7925 21.9107 47.8458 21.9647C47.8992 22.0187 47.9403 22.0838 47.9667 22.1553C47.9931 22.2269 48.004 22.3032 47.9986 22.3794C47.9933 22.4556 47.972 22.5297 47.9359 22.5968C47.8997 22.6638 47.8496 22.7223 47.7893 22.7681L37.2549 33.429C37.1427 33.4823 37.0146 33.4898 36.8969 33.4502Z"
      fill="#475467"
    />
  </svg>
);

export default SalesforceDevelopment;
